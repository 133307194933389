<template>
  <div>
    <v-card flat elevation="0">
      <v-toolbar dense elevation="0">
        <v-toolbar-title>{{ title }}</v-toolbar-title>

        <v-spacer></v-spacer>

        <v-text-field v-if="!isMobile" dense autofocus outlined rounded placeholder="Search" v-model="search" append-icon="mdi-magnify" style="max-width: 300px" hide-details single-line></v-text-field>

        <v-btn icon @click="dialogFilter=true">
          <v-icon>mdi-filter</v-icon>
        </v-btn>

        <v-btn icon @click="getData(true)" title="Refresh">
          <v-icon>mdi-sync</v-icon>
        </v-btn>
      </v-toolbar>

      <div class="px-2" v-if="isMobile">
        <v-text-field class="mb-2" dense clearable autofocus outlined placeholder="Search" v-model="search" append-icon="mdi-magnify" style="max-width: 3000px" hide-details single-line></v-text-field>
      </div>

      <v-data-table :options.sync="datatable_options" :loading="datatable.loading" :loading-text="datatable.loadingText" :headers="datatable.columns" :items="datatable.data" :search="search" :hide-default-header="isMobile" :server-items-length="datatable.totalRows" dense class="mb-3 my-0">
        <template v-slot:[`item.id`]="{ item }" v-if="!isMobile">
          <Edit :dataId="parseInt(item.id)">
            <template v-slot:activator="{ on }">
              <v-btn icon text x-small v-on:click="on">
                <v-icon>mdi-pencil</v-icon>
              </v-btn>
            </template>
          </Edit>
          <EditUser :dataId="parseInt(item.id)">
            <template v-slot:activator="{ on }">
              <v-btn icon text x-small v-on:click="on">
                <v-icon>mdi-account</v-icon>
              </v-btn>
            </template>
          </EditUser>
        </template>
        <template v-slot:[`item`]="{ item }" v-if="isMobile">
          <v-card elevation="4" class="ma-1" outlined>
            <v-card-title class="text-h5 mb-1 pb-1">{{ item.source_document }}</v-card-title>
            <v-card-subtitle class="my-0 py-0 mb-3">
              {{ item.document_type }} <br>
              {{ item.area_code }} <br>
              {{ item.description }}
            </v-card-subtitle>
            <v-card-actions>
              <v-spacer></v-spacer>
              <Edit :dataId="parseInt(item.id)">
                <template v-slot:activator="{ on }">
                  <v-btn icon text v-on:click="on">
                    <v-icon>mdi-pencil</v-icon>
                  </v-btn>
                </template>
              </Edit>
              <EditUser :dataId="parseInt(item.id)">
                <template v-slot:activator="{ on }">
                  <v-btn icon text v-on:click="on">
                    <v-icon>mdi-account</v-icon>
                  </v-btn>
                </template>
              </EditUser>
            </v-card-actions>
          </v-card>
        </template>
        <v-alert slot="no-results" :value="true" color="error" icon="warning">Your search for "{{ search }}" found no results.</v-alert>s
      </v-data-table>
    </v-card>


    <v-dialog v-model="dialogFilter" max-width="400px">
      <v-card>
        <v-card-title>Filter</v-card-title>
        <v-card-text>
          <v-autocomplete v-model="filterData.role" outlined dense class="mb-2" placeholder="Role" label="Role" :items="master.role"></v-autocomplete>
          <v-text-field v-model="filterData.document_type" outlined dense class="mb-2" placeholder="Document Type" label="Document Type"></v-text-field>
          <v-autocomplete v-model="filterData.area_code" outlined dense class="mb-2" placeholder="Area Code" label="Area Code" :items="master.area" item-text="area_code" item-value="area_code"></v-autocomplete>
          <v-autocomplete v-model="filterData.dept_code" outlined dense class="mb-2" placeholder="Department Code" label="Department Code" :items="master.department" item-text="dept_code" item-value="dept_name"></v-autocomplete>
        </v-card-text>
        <v-card-actions>
          <v-btn color="primary" @click="getData(true), dialogFilter = false">Filter</v-btn>
          <v-spacer></v-spacer>
          <v-btn @click="dialogFilter = false">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import Edit from './Edit.vue';
import EditUser from './EditUser.vue';
export default {
  props: {
    title: String,
    source_document: String,
  },
  components: {
    Edit,
    EditUser,
  },
  data() {
    return {
      loading: null,
      search: "",
      datatable_options: {},
      dialogFilter: false,
      datatable: {
        loading: null,
        loadingText: "Loading... Please wait...",
        totalRows: 0,
        columns: [
          { text: "#", value: "id" },
          { text: "Role", value: "role" },
          { text: "Source Document", value: "source_document" },
          { text: "Document Type", value: "document_type" },
          { text: "Area Code", value: "area_code" },
          { text: "Dept Code", value: "dept_code" },
          { text: "Description", value: "description" },
        ],
        data: [],
      },
      filterData: {
        role: null,
        source_document: null,
        document_type: null,
        area_code: null,
        description: null,
      },
      master: {
        role: [],
        source_document: [],
        area: [],
        department: [],
      },
      loading_master: false,
    };
  },

  watch: {
    datatable_options: {
      handler() {
        this.getData();
      },
      deep: true,
    },

    search: {
      handler() {
        this.getData();
      },
      deep: true,
    },

    // eslint-disable-next-line no-unused-vars
    dialogFilter(n, o){
      if(n == true){
        this.getMaster();
      }
    }
  },

  methods: {
    refreshData() {
      this.getData(true);
    },
    async getData(refresh) {

      if (refresh) this.AxiosStorageRemove("GET", "approvaltemplate/data");

      const { sortBy, sortDesc, page, itemsPerPage } = this.datatable_options;

      this.datatable.loading = true;
      const params = {
        search: this.search,
        sort_by: sortBy,
        sort_desc: sortDesc,
        page: page,
        limit: itemsPerPage,
        role: this.filterData.role,
        source_document: this.source_document,
        document_type: this.filterData.document_type,
        area_code: this.filterData.area_code,
        description: this.filterData.description,
      };

      await this.$axios
        .get("approvaltemplate/data", {
          params: params
        })
        .then((res) => {
          var resData = res.data;
          if (resData.status == "success") {
            this.datatable.data = resData.data.results;
            this.datatable.totalRows = resData.data.paging.total_rows;
          } else {
            this.showAlert(resData.status, resData.message);
          }
          this.datatable.loading = false;
        })
        .catch((error) => {
          this.axiosErrorHandler(error);
          this.datatable.loading = false;
        });
    },
    async getMaster() {
      this.loading_master = true;

      this.master.role = [];
      this.master.source_document = [];
      this.master.area = [];
      this.master.department = [];

      const uri = 'approvaltemplate/master';
      const config = {
        cacheConfig: false,
      };
      await this.$axios.get(uri, config)
        .then((res) => {
          const resData = res.data;
          if (resData.status == 'success') {

            this.master.role = resData.data.role;
            this.master.area = resData.data.area;
            this.master.source_document = resData.data.source_document;
            this.master.department = resData.data.department;

          } else {
            this.setAlert(resData.status, resData.message);
          }

          this.loading_master = false;
        })
        .catch((e) => {
          this.loading_master = false;
          this.axiosErrorHandler(e);
        });
    },
  },

  mounted() {
    this.getData()
  }
};
</script>