<template>
  <div class="d-inline">
    <slot name="activator" :on="showDialog"></slot>
    <!-- <div href="javascript:;" @click="showDialog()"> -->
    <slot></slot>
    <!-- </div> -->

    <v-dialog v-model="dialog" max-width="1000">
      <v-card :loading="loading">
        <v-card-title class="text-h5 grey lighten-2">
          <span>{{ approval_template.source_document }}</span>
          <v-spacer></v-spacer>

          <v-btn color="light" @click="dialog = false" icon>
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text class="pt-4">
          <v-row>
            <v-col md="6">
              <v-text-field :value="approval_template.source_document" label="Source Document" dense class="mb-6" hide-details readonly disabled></v-text-field>
              <v-text-field :value="approval_template.document_type" label="Document Type" dense class="mb-6" hide-details readonly disabled></v-text-field>
              <v-text-field :value="approval_template.description" label="Description" multi-line dense class="mb-6" hide-details disabled></v-text-field>
            </v-col>
            <v-col md="6">
              <v-text-field :value="approval_template.area_code" label="Area Code" dense class="mb-6" hide-details readonly disabled></v-text-field>
              <v-text-field :value="approval_template.dept_code" label="Department Code" dense class="mb-6" hide-details readonly disabled></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col>
              <v-simple-table dense fixed-header height="350">
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-left">Label</th>
                      <th class="text-left">Level</th>
                      <th class="text-left">User</th>
                      <th class="text-left">#</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <v-text-field dense hide-details placeholder="Label" class="mb-1" v-model="addUser_approval_label"></v-text-field>
                      </td>
                      <td>
                        <v-text-field dense hide-details placeholder="Level" class="mb-1" v-model="addUser_approval_level"></v-text-field>
                      </td>
                      <td>
                        <v-autocomplete dense hide-details placeholder="User" class="mb-1" :items="users" item-text="username" item-value="id" v-model="addUser_user_id"></v-autocomplete>
                      </td>
                      <td>
                        <v-btn title="Add" small color="blue" dark @click="addUser" :loading="addUser_loading">Add</v-btn>
                      </td>
                    </tr>
                    <tr v-for="item in approval_user" :key="item.id">
                      <td>{{ item.approval_label }}</td>
                      <td>{{ item.approval_level }}</td>
                      <td>{{ item.username }}</td>
                      <td>
                        <delete-user :id="item.id" @refresh-data="getData()"></delete-user>
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-col>
          </v-row>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="dialog = false"> Close </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import DeleteUser from "./DeleteUser";

export default {
  components: { DeleteUser },
  props: {
    dataId: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      dialog: false,
      loading: false,
      approval_template: {},
      approval_user: [],
      users: [],

      // Add User
      addUser_loading: false,
      addUser_approval_label: "",
      addUser_approval_level: 0,
      addUser_user_id: 0,
    };
  },

  methods: {
    /**
     * showDialog
     * Method untuk menampilkan dialog
    */
    showDialog() {
      this.dialog = true;
      this.getData();
    },

    /**
     * closeDialog
     * Method untuk menutup dialog
    */
    closeDialog() {
      this.dialog = false;
    },

    async getData() {
      this.loading = true;

      this.getUsers();

      var config = {
        params: {
          id: this.dataId,
        },
      };
      await this.$axios.get("approvaltemplate/detail", config).then((res) => {
        var resData = res.data;

        if (resData.status == "success") {
          this.approval_template = resData.data.approval_template;
          this.approval_user = resData.data.approval_user;

          // console.log(resData);
        }

        this.loading = false;
      });
    },

    async getUsers() {
      this.loading = true;
      await this.$axios.get("approvaltemplate/users").then((res) => {
        var resData = res.data;

        if (resData.status == "success") {
          this.users = resData.data.users;

          // console.log(resData);
        }

        this.loading = false;
      });
    },

    async addUser() {
      this.addUser_loading = true;
      var formData = new FormData();
      formData.append("approval_template_id", this.dataId);
      formData.append("approval_label", this.addUser_approval_label);
      formData.append("approval_level", this.addUser_approval_level);
      formData.append("user_id", this.addUser_user_id);

      await this.$axios
        .post("approvaltemplate/add-user", formData)
        .then((res) => {
          var resData = res.data;
          this.showAlert(resData.status, resData.message);
          this.addUser_loading = false;

          this.getData();
          this.clearAddUser();
        })
        .catch((error) => {
          this.axiosErrorHandler(error);
          this.addUser_loading = false;
        });
    },

    clearAddUser() {
      this.addUser_approval_label = null;
      this.addUser_approval_level = null;
      this.addUser_user_id = null;
    },

  },
};
</script>
