<template>
  <v-btn
    title="Delete"
    small
    color="red"
    dark
    @click="deleteUser()"
    :loading="loading"
    >Del</v-btn
  >
</template>

<script>
export default {
  props: ["id"],
  data() {
    return {
      loading: false,
    };
  },

  methods: {
    async deleteUser() {
      if (!confirm("Delete user?")) return;

      this.loading = true;
      var formData = new FormData();
      formData.append("id", this.id);

      await this.$axios
        .post("approvaltemplate/delete-user", formData)
        .then((res) => {
          var resData = res.data;
          this.showAlert(resData.status, resData.message);
          this.loading = false;

          this.$emit("refresh-data");
        })
        .catch((error) => {
          this.axiosErrorHandler(error);
          this.loading = false;
        });
    },
  },
};
</script>
