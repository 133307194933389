<template>
  <div>
    <v-container>
      <page-title title="Approval Templates">
        <template slot="action">
          <Add v-if="modulePermission('approvaltemplate', 'create')" class="ms-1" @callback="addCallback">
            <template v-slot:activator="{ on }">
              <v-btn @click="on">Add</v-btn>
            </template>
          </Add>
        </template>
      </page-title>

      <v-card class="mb-2">
        <v-tabs v-model="tab" background-color="primary" dark>
          <v-tab v-for="item in items" :key="item.tab">{{ item.tab }}</v-tab>
        </v-tabs>

        <v-tabs-items v-model="tab">
          <v-tab-item v-for="item in items" :key="item.tab">
            <ListData :ref="item.ref" :source_document="item.source_document" :title="item.title"></ListData>
          </v-tab-item>
        </v-tabs-items>
      </v-card>
    </v-container>
  </div>
</template>

<script>
import ListData from "./components/ListData.vue";

import Add from './components/Add.vue';

export default {
  components: {
    ListData,
    Add,
  },
  data() {
    return {
      search: "",
      tab: null,
      items: [
        {
          tab: "Berita Acara",
          title: "Berita Acara",
          source_document: "Berita Acara",
          ref: "refListDataBeritaAcara"
        },
        {
          tab: "Return Material Project",
          title: "Return Material Project",
          source_document: "RMP",
          ref: "refListDataRMP"
        },
        {
          tab: "CPAR",
          title: "CPAR",
          source_document: "CPAR",
          ref: "refListCPAR"
        },
        {
          tab: "MJO",
          title: "Maintenance Job Order",
          source_document: "MJO",
          ref: "refListMJO"
        },
      ],
    };
  },

  computed: {},

  methods: {
    addCallback() {
      try {
        const i = this.tab;

        if (typeof this.items[i].ref == 'undefined') return;

        const ref = this.items[i].ref;

        if (typeof this.$refs[ref][0] != 'undefined') {
          this.$refs[ref][0].refreshData();
        }
      } catch (e) {
        this.showAlert("warning", "Oops.. something went wrong!<br>" + e.message);
      }

    }
  },

  mounted() {
    this.requiredLogin();

    this.modulePermission("approvaltemplate", "view", true);
  },
};
</script>
